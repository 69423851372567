import React, { useEffect, useState } from 'react'

export const useKeyboardNavigation = (tabsRef: React.RefObject<HTMLAnchorElement[]>) => {
  const [focusIndex, setFocusIndex] = useState<number | null>(null)

  useEffect(() => {
    if (tabsRef.current && tabsRef.current.length > 0 && focusIndex !== null) {
      tabsRef.current[focusIndex].focus()
    }
  }, [focusIndex])

  const indexMin = 0
  const indexMax = (tabsRef.current && tabsRef.current.length - 1) || 0

  const nextTab = () => {
    const nextIndex = focusIndex ? focusIndex + 1 : 1

    if (nextIndex > indexMax) {
      setFocusIndex(indexMax)
      return
    }
    setFocusIndex(nextIndex)
  }

  const previousTab = () => {
    const previousIndex = focusIndex ? focusIndex - 1 : -1

    if (previousIndex < indexMin) {
      setFocusIndex(indexMin)
      return
    }
    setFocusIndex(previousIndex)
  }

  const changeFocus = (event: React.KeyboardEvent<HTMLAnchorElement>) => {
    event.preventDefault()

    switch (event.key) {
      case 'Tab':
        if (event.shiftKey) {
          previousTab()
          break
        }
        nextTab()
        break

      case 'ArrowRight':
        nextTab()
        break

      case 'ArrowLeft':
        previousTab()
        break

      case 'Escape':
        if (focusIndex && tabsRef.current) {
          tabsRef.current[focusIndex].blur()
        }
        setFocusIndex(null)
        break

      case 'Enter':
      case ' ':
        if (focusIndex && tabsRef.current) {
          tabsRef.current[focusIndex].click()
        }
        setFocusIndex(null)
        break
      default:
    }
  }

  return {
    changeFocus,
    focusIndex,
  }
}
