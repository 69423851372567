import { Item } from '@pretto/website/src/templates/blocs/rows/secondaryMenu/SecondaryMenu'

import { MutableRefObject, useEffect, useState } from 'react'

interface PrevNext {
  previous: boolean
  next: boolean
}

interface FirstTabsNotInViewPort {
  previous: number
  next: number
}

export const useTabsDisplay = (tabsRef: MutableRefObject<HTMLAnchorElement[]>, items: Item[]) => {
  const [formatedItems, setFormatedItems] = useState<Item[]>(items)
  const [isPrevNextdisplayed, setIsPrevNextdisplayed] = useState<PrevNext>({ previous: false, next: false })
  const [firstTabsNotInViewPort, setFirstTabsNotInViewPort] = useState<FirstTabsNotInViewPort>({
    previous: 0,
    next: 0,
  })
  const [windowInnerWidth, setWindowInnerWidth] = useState<number>(0)

  useEffect(() => {
    const handleWidoowResize = () => {
      setWindowInnerWidth(window.innerWidth)
    }
    window.addEventListener('resize', handleWidoowResize)

    return () => {
      window.removeEventListener('resize', handleWidoowResize)
    }
  }, [])

  useEffect(() => {
    handleScroll()
  }, [windowInnerWidth])

  const handleScroll = () => {
    if (tabsRef.current.length > 0) {
      const itemsWithVisibility = tabsRef.current.map((tab, i) => {
        const rect = tab.getBoundingClientRect()

        const isVisible =
          rect.top >= 0 &&
          rect.left >= 0 &&
          rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
          rect.right <= (window.innerWidth || document.documentElement.clientWidth)

        return { ...items[i], isInViewPort: isVisible }
      })

      setFirstTabsNotInViewPort({
        previous: itemsWithVisibility.findIndex(item => item.isInViewPort) - 1,
        next: itemsWithVisibility.findLastIndex(item => item.isInViewPort) + 1,
      })
      setIsPrevNextdisplayed({
        previous: !itemsWithVisibility[0].isInViewPort,
        next: !itemsWithVisibility[itemsWithVisibility.length - 1].isInViewPort,
      })
      setFormatedItems(itemsWithVisibility)
    }
  }

  if (typeof window !== 'undefined') {
    window.onresize = handleScroll
  }

  return {
    isPrevNextdisplayed,
    firstTabsNotInViewPort,
    formatedItems,
    handleScroll,
  }
}
