import { g } from '@pretto/bricks/components/layout'

import { ChevronRightBold } from '@pretto/picto'

import React from 'react'
import styled from 'styled-components'

export type Action = 'previous' | 'next'

type NextTabProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  action: Action
  color?: string
  onClick: (event: React.MouseEvent<HTMLButtonElement> | React.KeyboardEvent<HTMLButtonElement>) => void
}

export const NextTab: React.FC<NextTabProps> = ({ action = 'next', color = 'white', onClick, ...props }) => (
  <Container $color={color} action={action} onClick={onClick} {...props}>
    <Picto $action={action} />
  </Container>
)

interface ContainerProps {
  $color: string
  action: Action
}

interface PictoProps {
  $action: Action
}

const Container = styled.button<ContainerProps>`
  display: flex;
  width: ${g(8)};
  height: ${g(8)};
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  cursor: pointer;

  background: linear-gradient(
    '270deg',
    rgba(255, 255, 255, 0) 0%,
    ${({ theme, $color }) => theme.colors[$color]}; 102.34%
  );
  backdrop-filter: blur(10px);
`

const Picto = styled(ChevronRightBold)<PictoProps>`
  width: ${g(2)};
  height: ${g(2)};
  flex-shrink: 0;
  transform: ${({ $action }) => ($action === 'previous' ? 'rotate(180deg)' : 'none')};
`
