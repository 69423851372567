import { g } from '@pretto/bricks/components/layout'
import Link from '@pretto/bricks/website/utility/Link'

import { forwardRef } from 'react'
import styled, { css } from 'styled-components'

type TabProps = React.HTMLAttributes<HTMLAnchorElement> & {
  href: string
  isDisabled?: boolean
  isSelected?: boolean
  isInViewPort?: boolean
  label: string
  role?: string
}

type Ref = HTMLAnchorElement

export const Tab = forwardRef<Ref, TabProps>(
  ({ isDisabled, isSelected, isInViewPort = false, label, ...props }, ref) => (
    <Container
      $isDisabled={isDisabled}
      $isSelected={isSelected}
      $isTabInViewPort={isInViewPort}
      ref={ref}
      tabIndex={isDisabled ? -1 : 0}
      {...props}
    >
      {label}
      <SelectedBorder $isSelected={isSelected} />
    </Container>
  )
)

Tab.displayName = 'Tab'

interface ContainerProps {
  $isDisabled?: boolean
  $isSelected?: boolean
  $isTabInViewPort?: boolean
}

const SelectedBorder = styled.div<ContainerProps>`
  display: none;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 2px;
  background-color: ${({ theme }) => theme.colors.primary2};
  border-radius: ${g(1)};
  animation-duration: 0.5s;
  animation-name: bouncing;

  ${({ $isSelected }) =>
    $isSelected &&
    css`
      display: block;
    `};

  @keyframes bouncing {
    0% {
      left: 50%;
      right: 50%;
    }
    40% {
      left: ${g(-1)};
      right: ${g(-1)};
    }
    100% {
      left: 0;
      right: 0;
    }
  }
`

const Container = styled(Link)<ContainerProps>`
  position: relative;
  ${({ theme }) => theme.typos.link};
  padding: ${g(3)};
  flex-shrink: 0;

  color: ${({ $isDisabled, $isTabInViewPort, theme }) =>
    $isDisabled || !$isTabInViewPort ? theme.colors.neutral2 : theme.colors.neutral1};
  transition: color 0.2s ease-in-out;

  &:focus ${SelectedBorder}, &:hover ${SelectedBorder} {
    ${({ $isDisabled }) =>
      !$isDisabled &&
      css`
        display: block;
      `};
  }
`
